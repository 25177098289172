import React, { useEffect, useMemo, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AccordionItem from 'components/accordion2';

const MiddleSection = () => {
  const steps = [
    {
      id: 1,
      img: 'https://www.bricknbolt.com/c/img/webp/how-it-works-2.webp',
      label: 'Raise a<br/>Request',
      description:
        'Raise a service request or call us at 7505205205. Our technical expert will get in touch with you.',
    },
    {
      id: 2,
      img: 'https://www.bricknbolt.com/c/img/webp/how-it-works-3.webp',
      label: 'Meet our<br/>Expert',
      description:
        'Our experts visit you to take all the requirements and submit exhaustive specifications.',
    },
    {
      id: 3,
      img: 'https://www.bricknbolt.com/c/img/webp/how-it-works-4.webp',
      label: 'Book with<br/>us',
      description:
        'Good to go. You pay 5% of the estimated project cost as Booking Amount.',
    },
    {
      id: 4,
      img: 'https://www.bricknbolt.com/c/img/webp/how-it-works-5.webp',
      label: 'Receive<br/>Plans',
      description:
        'Our Professional will provide exhaustive drawings and designs till the customer is fully satisfied. Designs include floor plans, 3D elevations, electrical, plumbing and structural designs.',
    },
    {
      id: 5,
      img: 'https://www.bricknbolt.com/c/img/webp/how-it-works-6.webp',
      label: 'Track &<br/>Transact',
      description:
        'To ensure absolute trust, Brick&Bolt provides an escrow model where you transfer the amount for stage of the project. You can track the project through our customer MiddleSectionlication.',
    },
    {
      id: 6,
      img: 'https://www.bricknbolt.com/c/img/webp/how-it-works-7.webp',
      label: 'Settle<br/>in',
      description:
        "We make sure you are well settled in your new home. Our journey together doesn't end here. We provide 5 years of warranty.",
    },
  ];

  const [selectedStep, setSelectedStep] = useState(0);

  const interValId = useRef();

  useEffect(() => {
    interValId.current = setInterval(() => {
      clearInterval(interValId.current);
      setSelectedStep((prev) => {
        if (prev === steps.length - 1) return 0;
        return prev + 1;
      });
    }, 5000);

    return () => {
      clearInterval(interValId.current);
    };
  }, [selectedStep]);

  const fadeVariant = useMemo(
    () => ({
      enter: { opacity: 0 },
      center: { opacity: 1, transition: { duration: 0.3 } },
      exit: { opacity: 0, transition: { duration: 0.3 } },
    }),
    []
  );

  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const accordionData = [
    {
      title: '1. What is Febit',
      content:
        'Febit is a tech-enabled construction company simplifying home construction. It uses technology-based solutions to deliver high quality homes on time & within budget',
    },
    {
      title: '2. Is Febit an authentic company?',
      content:
        'Yes, Febit is an authentic company registered as Pluckwalk Technologies Pvt. Ltd. It currently employs over 300 people and has been recognized for its achievements in Forbes India, The Economic Times, etc.',
    },
    {
      title: '3. Is it more expensive than local contractor?',
      content:
        'No, Febit is not more expensive than a local contractor. It offers packages at par with market rates and ensures 0 cost overruns during the construction project.',
    },
  ];

  return (
    <div className="w-full">
      {/* <div className="flex flex-col items-center justify-center bg-[rgb(255,246,244)] p-4 w-full  py-11">
        <h1 className="text-2xl  text-center font-bold mb-8 text-[rgb(31,7,86)]">
          We serve our customers better than others
        </h1>

        <div className="flex flex-col md:flex-row justify-center items-center gap-8 mb-6">
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4 underline text-center">
              Febit
            </h2>
            <ul className="list-none space-y-2 flex flex-col items-start text-[#1F0756]">
              <li>✅ ESCROW Facility for 100% Money Safety</li>
              <li>✅ Top Quality Assurance with QASCON</li>
              <li>✅ Monitor The Progress From Home using MiddleSection</li>
              <li>✅ ZERO Cost Overruns</li>
              <li>✅ Guaranteed On Time Delivery</li>
            </ul>
          </div>
          <div className="flex flex-1 border border-dashed border-[#F05A29] h-[200px]" />
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4 underline text-center text-[#1F0756]">
              Contractors
            </h2>
            <ul className="list-none space-y-2 flex flex-col items-start">
              <li>❌ No Safety of Money & High Risk</li>
              <li>❌ Improper quality checks</li>
              <li>❌ No MiddleSection. Have to go to construction site</li>
              <li>❌ Costs keep increasing</li>
              <li>❌ No guarantee on timely delivery</li>
            </ul>
          </div>
        </div>

        <h2 className="text-center text-xl text-[#1F065A]">
          Experience Tech-Enabled Construction with us
        </h2>
        <button className="mt-7 bg-orange-600 text-white font-bold py-2 px-4 rounded  hover:bg-orange-700 duration-200 h-12">
          Start Your Construction
        </button>
      </div> */}

      <div className=" flex flex-col items-center justify-center bg-[rgb(255,246,244)] p-4 w-full  py-11">
        <h2 className="text-3xl text-[rgb(52,6,86)] font-bold mb-3">
          How it works
        </h2>
        <p className="w-80 max-md:m-w-full max-md:w-full leading-5 text-center text-[rgb(52,6,86)] ">
          Our house construction steps are simple and easy to understand: Plan -
          Build - Track - Settle in.
        </p>
        <div className="flex justify-center my-9 max-sm:zoom-out container !border-none">
          {steps.map((step, index, array) => (
            <React.Fragment key={step.id}>
              <div className="flex flex-col items-center">
                <span
                  onClick={() => {
                    setSelectedStep(index);
                  }}
                  className={`font-bold w-2 h-2 p-4 flex items-center justify-center rounded-full cursor-pointer border-black border ${
                    selectedStep >= index
                      ? 'text-white bg-black'
                      : 'text-black bg-transparent'
                  }`}
                >
                  {step.id}
                </span>
                <span
                  dangerouslySetInnerHTML={{ __html: step.label }}
                  className={`text-sm whitespace-pre leading-[18px] mt-4 font-semibold text-center  ${
                    selectedStep >= index ? 'text-black' : 'text-gray-500 '
                  }  `}
                />
              </div>
              <div
                className={`w-[100px] mb-5 max-md:w-[25px] border border-gray-600 border-dashed h-[1px] self-center top-0 ${
                  array.length - 1 === index && 'hidden'
                }`}
              />
            </React.Fragment>
          ))}
        </div>

        <div className="w-fit h-fit mt-1 mb-7 h-74">
          <AnimatePresence mode="wait">
            <motion.img
              key={steps[selectedStep].id}
              src={steps[selectedStep].img}
              alt={steps[selectedStep].label.replace('<br/>', ' ')}
              className="w-74 h-74 aspect-1/1 mix-blend-multiply max-md:w-[100%] max-md:max-w-[100%]"
              variants={fadeVariant}
              initial="enter"
              animate="center"
              exit="exit"
            />
          </AnimatePresence>
        </div>

        <div className="container bg-white p-8 rounded-lg shadow-md flex overflow-hidden">
          <AnimatePresence mode="wait">
            <motion.div
              key={steps[selectedStep].id}
              variants={fadeVariant}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <h3 className="text-xl font-bold mb-2">
                {selectedStep + 1}.{' '}
                {steps[selectedStep].label.replace('<br/>', ' ')}
              </h3>
              <p className="text-gray-700 mb-6 max-sm:indent-0 indent-5 leading-6">
                {steps[selectedStep].description}
              </p>
            </motion.div>
          </AnimatePresence>
        </div>

        <button className="mt-4 mb-2 bg-orange-600 text-white font-bold py-2 px-4 rounded  hover:bg-orange-700 duration-200 h-12">
          Start Your Construction
        </button>
      </div>
      {/* <div className="flex flex-col items-center justify-center bg-white p-4 w-full pt-8 pb-11">
        <h2 className="text-3xl text-[rgb(52,6,86)] font-bold mb-3">FAQ</h2>
        {accordionData.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            content={item.content}
            isOpen={openIndex === index}
            onClick={() => handleClick(index)}
          />
        ))}
      </div> */}
    </div>
  );
};

export default MiddleSection;
