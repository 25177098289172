import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

import CustomSwiper from 'components/Swiper/Swiper';

import { breakpoints } from '../HomeSwipers';
import { costOfConstructionData } from '.';

const CostOfConstructionSwiper = () => {
  const [showTitle, setShowTitle] = useState(null);
  const textVariants = {
    initial: {
      opacity: 0.2,
      x: -10,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };

  const navigate = useNavigate();

  return (
    <div className="w-[90%] mx-auto flex flex-col gap-4 mt-9">
      <h1 className="text-[20px] font-semibold">Cost of Construction</h1>
      <CustomSwiper
        showButton={false}
        paginated
        breakpoints={breakpoints}
        swiperSlideContent={Object.values(costOfConstructionData)?.map(
          (item, index) => (
            <SwiperSlide
              onClick={() => {
                navigate(
                  `/cost-of-construction/know-more/${item.title
                    .replace(/[\s-]+/g, '_')
                    .toLowerCase()}`
                );
              }}
              style={{ minHeight: '400px' }}
            >
              <div className="overflow-hidden rounded-md shadow-1 h-[350px] object-cover">
                <div
                  className="w-full h-full relative"
                  onMouseOver={() => setShowTitle(index)}
                  onMouseLeave={() => setShowTitle(null)}
                >
                  <img
                    src={item.preview.source}
                    alt={`swiper-img-${item.title}`}
                    className="h-full hover:scale-[1.1] transition-all duration-300 cursor-pointer ease-in w-full object-cover"
                  />
                  {showTitle === index && (
                    <motion.p
                      variants={textVariants}
                      initial="initial"
                      animate="animate"
                      className="absolute bottom-[20px] left-[10px] text-white text-[18px] font-medium"
                    >
                      {item.title}
                    </motion.p>
                  )}
                </div>
              </div>
            </SwiperSlide>
          )
        )}
      />
    </div>
  );
};

export default CostOfConstructionSwiper;
