const contents = {
  design_and_planning: {
    title: 'Design and Planning',
    preview: {
      type: 'image',
      source:
        'https://www.drfbuilders.com/wp-content/uploads/2019/05/design_build_construction.jpg',
    },
    content:
      "Our experienced architects and designers will work closely with you to bring your vision to life. From conceptual designs to detailed plans, we'll handle every aspect.",
    detailedInfo: [
      {
        type: 'info',
        title: 'Conceptual Design',
        description:
          'Our architects and designers will collaborate with you to develop creative and functional conceptual designs that capture your vision and meet your needs.',
      },
      {
        type: 'info',
        title: 'Detailed Planning',
        description:
          "Once the conceptual design is approved, we'll create detailed construction documents, including floor plans, elevations, and specifications, to guide the construction process.",
      },
      {
        type: 'info',
        title: 'Permitting and Approvals',
        description:
          'Our team will handle all necessary permitting and approvals, ensuring compliance with local building codes and regulations throughout the project.',
      },
    ],
  },
  construction_management: {
    title: 'Construction Management',
    preview: {
      type: 'image',
      source:
        'https://media.istockphoto.com/id/1333158315/photo/civil-engineer-jobs-double-exposure-of-project-management-team-and-construction-site-with.jpg?s=612x612&w=0&k=20&c=1_3sEY8vgL_UFzNmlDUrdy1uYu8Xh9K-CoXqo4OgyIw=',
    },
    content:
      "Leave the construction process to our expert team. We'll manage all aspects of the project, from scheduling to quality control, ensuring a seamless and stress-free experience.",
    detailedInfo: [
      {
        type: 'info',
        title: 'Project Scheduling',
        description:
          'Our construction managers will develop a detailed project schedule, coordinating all trades and tasks to ensure efficient and timely completion of your project.',
      },
      {
        type: 'info',
        title: 'On-site Supervision',
        description:
          'Our dedicated site supervisors will oversee the construction process, ensuring adherence to plans, quality standards, and safety regulations throughout the project.',
      },
      {
        type: 'info',
        title: 'Progress Reporting',
        description:
          "Regular progress reports and communication will keep you informed every step of the way, allowing you to track the project's status and make informed decisions.",
      },
      {
        type: 'info',
        title: 'Quality Control',
        description:
          'Our stringent quality control measures, including inspections and testing, ensure that every aspect of the construction meets the highest standards of workmanship and material quality.',
      },
      {
        type: 'info',
        title: 'Punch List and Closeout',
        description:
          "At the end of the project, we'll conduct a thorough punch list inspection and address any remaining items to ensure your complete satisfaction before project closeout.",
      },
    ],
  },
  project_consultation: {
    title: 'Project Consultation',
    preview: {
      type: 'image',
      source:
        'https://www.shutterstock.com/image-photo/team-diverse-partners-mature-latin-600nw-2346448085.jpg',
    },
    content:
      'Need guidance or expert advice? Our team of consultants is ready to assist you with any aspect of your construction project, from feasibility studies to cost analysis.',
    detailedInfo: [
      {
        type: 'info',
        title: 'Feasibility Studies',
        description:
          'Our consultants will conduct comprehensive feasibility studies to assess the viability of your project, taking into account factors such as site conditions, zoning regulations, and market demand.',
      },
      {
        type: 'info',
        title: 'Cost Analysis',
        description:
          'Accurate cost estimation is crucial for successful project planning. Our experts will provide detailed cost analyses, considering materials, labor, and other project-related expenses.',
      },
      {
        type: 'info',
        title: 'Risk Assessment',
        description:
          "We'll identify and assess potential risks associated with your project, helping you develop mitigation strategies to minimize disruptions and ensure a successful outcome.",
      },
      {
        type: 'info',
        title: 'Value Engineering',
        description:
          'Our value engineering services help optimize project costs while maintaining quality, functionality, and design integrity, ensuring you get the most value for your investment.',
      },
      {
        type: 'info',
        title: 'Project Monitoring',
        description:
          'For ongoing projects, our consultants can provide regular monitoring and advisory services, ensuring adherence to plans, budgets, and timelines, and addressing any challenges that may arise.',
      },
    ],
  },
  post_construction_services: {
    title: 'Post-Construction Services',
    preview: {
      type: 'image',
      source:
        'https://st3.depositphotos.com/9880800/17035/i/450/depositphotos_170350080-stock-photo-construction-workers-shaking-hands.jpg',
    },
    content:
      "Our commitment doesn't end with construction. We offer comprehensive post-construction services, including maintenance, warranties, and ongoing support.",
    detailedInfo: [
      {
        type: 'infoWithImage',
        title: 'Warranty Management',
        image:
          'https://antmyerp.com/wp-content/uploads/2022/01/Embrace-the-Ease-with-our-Product-Warranty-Management-Software.webp',
        description:
          "We'll manage all warranties and guarantees related to materials, workmanship, and equipment, ensuring that any issues are addressed promptly and efficiently.",
      },
      {
        type: 'info',
        title: 'Maintenance and Repair',
        description:
          'Our skilled technicians can provide ongoing maintenance and repair services, helping to extend the life and preserve the quality of your building or property.',
      },
      {
        type: 'info',
        title: 'Building Systems Support',
        description:
          "Our team can offer support and guidance for the operation and maintenance of your building's systems, including HVAC, plumbing, electrical, and security systems.",
      },
      {
        type: 'info',
        title: 'Tenant Coordination',
        description:
          'For commercial properties, we can assist with tenant coordination, ensuring a smooth transition and addressing any tenant-related issues or requests.',
      },
      {
        type: 'info',
        title: 'Facility Management',
        description:
          'Our comprehensive facility management services include building operations, maintenance, and optimization, helping you maximize the efficiency and performance of your property.',
      },
    ],
  },
  sustainable_construction: {
    title: 'Sustainable Construction',
    preview: {
      type: 'image',
      source:
        'https://content.cdntwrk.com/files/aHViPTgyNzM5JmNtZD1pdGVtZWRpdG9yaW1hZ2UmZmlsZW5hbWU9aXRlbWVkaXRvcmltYWdlXzYyMjllZTk2ZTgwN2MuanBnJnZlcnNpb249MDAwMCZzaWc9MjQ1NGE4NTU1Y2NkNTE5ZjA3NjJkYjRkNmU1YmEyYWU%253D',
    },
    content:
      'We prioritize sustainable and environmentally friendly construction practices, helping you reduce your carbon footprint and create energy-efficient, eco-friendly buildings.',

    detailedInfo: [
      {
        type: 'info',
        title: 'Energy-Efficient Design',
        image: '',
        description:
          'Our architects and engineers will incorporate energy-efficient design principles, ensuring optimal insulation, lighting, and HVAC systems to minimize energy consumption.',
      },
      {
        type: 'info',
        title: 'Sustainable Materials',
        description:
          'We prioritize the use of sustainable, recycled, and locally sourced materials whenever possible, reducing the environmental impact of your project.',
      },
      {
        type: 'info',
        title: 'Water Conservation',
        description:
          'Our water conservation strategies include the installation of low-flow fixtures, rainwater harvesting systems, and efficient landscaping to minimize water usage.',
      },
      {
        type: 'info',
        title: 'Waste Management',
        description:
          "Comprehensive waste management plans ensure proper sorting, recycling, and responsible disposal of construction waste, minimizing the project's environmental footprint.",
      },
      {
        type: 'info',
        title: 'Green Building Certification',
        description:
          'We can assist in obtaining green building certifications, such as LEED or Energy Star, demonstrating your commitment to sustainability and environmental responsibility.',
      },
    ],
  },
};

export default contents;
