import cardImg1 from 'assets/Images/KitchenDesign/cardImg1.jpg';

export const cardList = [
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680689854662cf77d727fda6-HLKT00000817_kitchen-800x600_3-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [
        cardImg1,
        cardImg1,
        'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
      ],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: cardImg1,
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '4 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '7 sq. ft',
    },
  },
  {
    title: 'Card Title',
    type: 'info',
    tip: 'Perfect module design',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: cardImg1,
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '2 sq. ft',
    },
  },
  {
    title: 'Card Title',
    type: 'info',
    tip: 'Perfect module design',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    type: 'info',
    tip: 'Perfect module design',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
];
