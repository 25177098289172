import Modal from 'components/Modal/Modal';
import CustomSwiper from 'components/Swiper/Swiper';
import React from 'react';
import BodyContent from './_legos/BodyContent';

const CardModal = ({ isOpen, onClose, onOpen, cardList, idx }) => {
  console.log('cardList', cardList);
  const heading = <h1>heading</h1>;
  const bodyContent = (
    <BodyContent onClose={onClose} idx={idx} cardList={cardList} />
  );
  return (
    <Modal
      heading={heading}
      body={bodyContent}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default CardModal;
