import DesignPage from 'components/DesignPage/DesignPage';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import React from 'react';
import BannerDesktop from 'assets/Images/KitchenDesign/bannerDesktop.jpg';
import BedroomBanner from 'assets/Images/BedroomDesign/banner.webp';
import { cardList } from '.';

const KitchenDesign = () => {
  useScrollToTopOnRouteChange();
  return (
    <div className="">
      <DesignPage
        BannerDesktop="https://super.homelane.com/HL_tiles/banners/desktop/modular_kitchen_designs.jpg"
        BannerImage={BedroomBanner}
        cardList={cardList}
        heading="Kitchen Designs"
        bannerHeading={
          <h1 className="">Finest Modular Kitchens For All Your Needs</h1>
        }
      />
    </div>
  );
};

export default KitchenDesign;
