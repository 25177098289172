import React, { useState } from 'react';

const Banner = () => {
  const [state, setState] = useState();
  return (
    <div className="mt-[65px] flex items-center justify-center h-[90vh] design-banner-bg">
      <div className="relative flex justify-center items-center">
        <p className="max-w-[360px] leading-[130%] text-center m-auto text-[32px]  text-white font-[400] tracking-wider">
          Design <span className="text-primaryC">your home</span> with the best
          in class styles
        </p>
      </div>
    </div>
  );
};

export default Banner;
