import DesignPage from 'components/DesignPage/DesignPage';
import React from 'react';
import BannerImage from 'assets/Images/BedroomDesign/banner.webp';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import { cardList } from '.';

const BedroomDesign = () => {
  useScrollToTopOnRouteChange();

  return (
    <div>
      <DesignPage
        heading="Bedroom Designs"
        BannerImage={BannerImage}
        cardList={cardList}
        BannerDesktop="https://super.homelane.com/HL_tiles/banners/desktop/nera_bathroom.jpg"
      />
    </div>
  );
};

export default BedroomDesign;
