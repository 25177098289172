import BedroomDesign from 'pages/BedroomDesign/BedroomDesign';
import ContactUs from 'pages/ContactUs/ContactUs';
import Design from 'pages/Design/Design';
import Home from 'pages/Home/Home';
import KitchenDesign from 'pages/KitchenDesign/KitchenDesign';
import WardrobeDesign from 'pages/WardrobeDesign/WardrobeDesign';
import E2EKnowMoreDynamic from 'pages/E2EKnowMoreDynamic';
import InteriorKnowMoreDynamic from 'pages/InteriorKnowMoreDynamic';
import ConstOfConstructionKnowMoreDynamic from 'pages/ConstOfConstructionKnowMoreDynamic';

import React from 'react';
import { Routes, Route } from 'react-router-dom';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/design" element={<Design />} />
    <Route path="/design/kitchen" element={<KitchenDesign />} />
    <Route path="/design/bedroom" element={<BedroomDesign />} />
    <Route path="/design/wardrobe" element={<WardrobeDesign />} />
    <Route
      path="/end-to-end-offering/know-more/:offertype"
      element={<E2EKnowMoreDynamic />}
    />
    <Route
      path="/interior/know-more/:offertype"
      element={<InteriorKnowMoreDynamic />}
    />
    <Route
      path="/cost-of-construction/know-more/:offertype"
      element={<ConstOfConstructionKnowMoreDynamic />}
    />
    <Route path="/contact-us" element={<ContactUs />} />
  </Routes>
);

export default AppRoutes;
