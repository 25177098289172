import React from 'react';
import InfiniteHorizontalScroll from 'components/InfniteHorizontalScroll/InfiniteHorizontalScroll';
import CustomSwiper from 'components/Swiper/Swiper';
import { SwiperSlide } from 'swiper/react';
import Footer from 'components/Footer/Footer';
import WCU1 from 'assets/Icons/Home/WhyChooseUs/WCU1';
import { ourPartners } from 'pages/Design';
import ContactUs from 'pages/ContactUs/ContactUs';
import MiddleSection from './_components/MiddleSection/MiddleSection';
import Hero from './_components/Hero/Hero';
import DesignerForm from './_components/DesignerForm/DesignerForm';
import HomeSwipers from './_components/HomeSwipers/HomeSwipers';
import Estimates from './_components/Estimates/Estimates';
import {
  swiperBedroomSection,
  swiperContent1,
  swiperContent2,
  swiperKitchenSection,
  wardrobeSwiperSection,
} from '.';
import DesignSession from './_components/DesignSession/DesignSession';
import Prices from './_components/Prices/Prices';
import InteriorsSwiper from './_components/InteriorsSwiper/InteriorsSwiper';
import EndToEndOffering from './_components/EndToEndOffering/EndToEndOffering';
import CostOfConstructionSwiper from './_components/ConstOfConstructionSwiper/CostOfConstructionSwiper';

const Home = () => (
  <div className="w-full mt-[68px]">
    <Hero />
    <DesignerForm />
    <div className="flex flex-col gap-[5px] mt-12">
      <h1 className="text-center font-medium text-[18px] mb-3 font-bold">
        Our Partners
      </h1>
      <InfiniteHorizontalScroll items={ourPartners} />
    </div>

    {/* Swipers */}
    <InteriorsSwiper />
    <EndToEndOffering />
    <CostOfConstructionSwiper />
    {/* <HomeSwipers
      paginated
      swiperContent={swiperContent1}
      title="End-to-End Offerings"
      url="/design"
    /> */}
    {/* <HomeSwipers
      swiperContent={swiperKitchenSection}
      title="Modular Kitchen Designs"
      url="/design/kitchen"
    />
    <HomeSwipers
      swiperContent={wardrobeSwiperSection}
      title="Wardrobe Designs"
      url="/design/wardrobe"
    />
    <HomeSwipers
      swiperContent={swiperBedroomSection}
      title="Living Room Designs"
      url="/design/bedroom"
    /> */}
    {/* <div className="w-[95%] md:w-[90%]  m-auto py-[40px] md:py-[80px] flex flex-col gap-[5px]">
      <h1 className="text-center text-[24px] text-neutral-700 font-medium">
        Our Partners
      </h1>
      <CustomSwiper
        paginated
        swiperSlideContent={ourPartners.map((item) => (
          <SwiperSlide>
            <div>
              <img
                src={item.img}
                alt={`partner-${item.img}`}
                className=" w-[100px] md:w-[175px] hover:grayscale-0 object-contain transition-all duration-200 ease-in"
              />
            </div>
          </SwiperSlide>
        ))}
        breakpoints={{
          0: {
            slidesPerView: 2.5,
            slidesPerGroup: 1,
            delay: 1000,
            spaceBetween: 10,
          },

          1024: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            delay: 1000,
            spaceBetween: 20,
          },
          1224: {
            slidesPerView: 6,
            slidesPerGroup: 1,
            delay: 1000,
            spaceBetween: 20,
          },
        }}
      />navbar

    </div> */}
    {/* <Prices /> */}
    <MiddleSection />
    {/* <DesignSession /> */}
    <Estimates />
    <ContactUs />
  </div>
);

export default Home;
