import Input from 'components/Input/Input';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'sonner';

const DesignerForm = ({ w, customStyle = {} }) => {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    pincode: '',
  });
  const handleFormFieldChange = (e) => {
    console.log(e.target.name, e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  function isValidEmail(email) {
    // Regular expression to validate email format
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }
  function isValidNumber(number) {
    // Regular expression to validate number format
    const regex = /^[0-9]{10}$/;
    return regex.test(number);
  }
  function isValidPincode(pincode) {
    // Regular expression to validate pincode format
    const regex = /^[0-9]{6}$/;
    return regex.test(pincode);
  }
  const sendEmail = (e) => {
    e.preventDefault();
    if (!isValidEmail(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }
    if (!isValidNumber(formData.number)) {
      toast.error('Please enter a valid phone number');
      return;
    }
    if (!isValidPincode(formData.pincode)) {
      toast.error('Please enter a valid pincode');
      return;
    }

    emailjs
      .sendForm(
        'service_uv1f95g',
        'template_188rqvl',
        formRef.current,
        'AArrxq1S6Ul3yYZIk'
      )
      .then(
        (result) => {
          toast.success('Email sent successfully');
        },
        (error) => {
          console.log(error);
          toast.error('Something went wrong, please try again later');
        }
      );
  };
  return (
    <section
      className="block md:hidden mt-[20px] mb-[20px]"
      style={{ width: w || 'auto', ...customStyle }}
    >
      <h1 className="pl-[20px] py-[12px] text-[25px] font-semibold text-neutral-700">
        Contact Us
      </h1>
      <form
        onSubmit={sendEmail}
        ref={formRef}
        className="flex flex-col gap-[12px]"
        style={{ width: w || 'auto' }}
      >
        <div className="flex flex-col gap-[12px]  w-[90%] m-auto">
          <Input
            onChange={handleFormFieldChange}
            name="name"
            placeholder="Write your name"
            size="md"
          />
          <Input
            onChange={handleFormFieldChange}
            name="email"
            placeholder="Write your email"
            type="email"
            size="md"
          />
          <Input
            onChange={handleFormFieldChange}
            name="number"
            placeholder="Write your phone number"
            type="number"
            size="md"
          />
          <Input
            onChange={handleFormFieldChange}
            name="pincode"
            placeholder="Write your adress pincode"
            type="number"
            size="md"
          />
          <button
            type="submit"
            className="flex justify-center gap-[10px] bottom-[20px] rounded-[10px] shadow-1 py-2 px-4 mt-[12px] bg-bgPrimary text-white"
          >
            <p>Start Your Free Trial </p>
            <button className="bg-[#ffdf11] button-new text-neutral-900 text-[12px] px-2 h-[fit-content]">
              New
            </button>
          </button>
        </div>
      </form>
    </section>
  );
};

export default DesignerForm;
