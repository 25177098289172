import DesignPage from 'components/DesignPage/DesignPage';
import React from 'react';
import BannerImage from 'assets/Images/BedroomDesign/banner.webp';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import { cardList } from '.';

const WardrobeDesign = () => {
  useScrollToTopOnRouteChange();

  return (
    <div>
      <DesignPage
        heading="Wardrobe Designs"
        cardList={cardList}
        BannerImage={BannerImage}
        BannerDesktop="https://super.homelane.com/HL_tiles/banners/desktop/wardrobes.jpg"
        bannerHeading={
          <h1 className="">Best in Class Wardrobes That Makes Life Easier</h1>
        }
      />
    </div>
  );
};

export default WardrobeDesign;
