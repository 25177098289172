import React from 'react';
import { cardListItems } from '.';

const CardList = () => (
  <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-4 w-[99%] md:w-[90%] m-auto place-items-center py-[20px] gap-[20px]">
    {cardListItems.map((item) => (
      <div className="flex flex-col gap-[10px]  shadow-1 relative pb-[10px]">
        <img
          src={item.img}
          alt="project"
          className="w-[400px] h-[400px] md:max-w-[300px] md:max-h-[300px] object-cover"
        />
        <div className="bg-bgSecondary absolute right-[5%] bottom-[20%] py-[10px] px-[8px] text-white ">
          2 BHK
        </div>
        <div className="py-[10px] px-[10px] flex flex-col gap-[10px]">
          <p className="text-[16px]">{item.desc}</p>
          <p className="text-[12px] font-medium">Size: {item.size}</p>
        </div>
      </div>
    ))}
  </div>
);

export default CardList;
