import Logo from 'assets/Icons/logo_brand.jpg';
import React from 'react';

import { MapPin, Phone, Mail } from 'lucide-react';
import { footerIcons } from '.';

const Footer = () => (
  <footer className="flex flex-col gap-8 justify-between py-10 text-white bg-white">
    <div className="w-[95%] mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 items-start">
        {/* Logo and Social Icons Section */}
        <div className="flex flex-col w-fit justify-end mt-auto items-center md:items-start gap-3">
          <div className="flex flex-col gap-2">
            <img
              src={Logo}
              className="w-[170px] ml-[-8px] self-start justify-end "
              alt="Company Logo"
            />
            <div className="flex items-center gap-3">
              {footerIcons.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center p-2 rounded-full bg-bgPrimary hover:bg-opacity-80 transition-all cursor-pointer"
                >
                  {item.icon}
                </div>
              ))}
            </div>
          </div>
          {/* Phone */}
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <Phone className="w-5 h-5 text-gray-900" />
              <a
                href="tel:+919876543210"
                className="text-gray-900 hover:text-gray-700 transition-colors"
              >
                +91 6363199108
              </a>
            </div>

            <div className="flex items-center gap-2">
              <Mail className="w-5 h-5 text-gray-900" />
              <a
                href="mailto:contact@example.com"
                className="text-gray-900 hover:text-gray-700 transition-colors"
              >
                sales@febitinfrasolutions.com
              </a>
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="flex flex-col items-end gap-6 text-gray-900">
          <h3 className="text-xl font-semibold text-white">Contact Us</h3>

          <div className="flex flex-col gap-4">
            {/* Address */}
            <div className="flex items-center gap-3">
              <MapPin className="w-5 h-5 mt-1 text-gray-900" />
              <p className="text-gray-900 leading-relaxed">
                Valli Complex, Kabirmutt Rd,
                <br />
                Sunkenahalli, Gavipuram Extn,
                <br />
                Gavipuram Extension, Kempegowda Nagar,
                <br />
                Bengaluru, Karnataka 560019
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="mt-8 pt-8 border-t border-gray-800">
        <p className="text-center text-gray-900 text-sm">
          © {new Date().getFullYear()} Febit. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
