import Calendar from 'assets/Icons/Global/Calendar';
import Clock from 'assets/Icons/Global/Clock';
import HomeIcon from 'assets/Icons/Global/HomeIcon';
import Security from 'assets/Icons/Global/Security';

export const breakpoints = {
  0: {
    slidesPerView: 1,
    slidesPerGrpeoup: 1,
    delay: 1000,
    spaceBetween: 10,
  },

  1024: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    delay: 1000,
    spaceBetween: 20,
  },
  1224: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    delay: 1000,
    spaceBetween: 20,
  },
};

export const modalIconsItems = [
  {
    Icon: Calendar,
  },
  {
    Icon: HomeIcon,
  },
  {
    Icon: Clock,
  },
  {
    Icon: Security,
  },
];
