import CustomSwiper from 'components/Swiper/Swiper';
import React, { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import Close from 'assets/Icons/Global/Close';
import InfiniteHorizontalScroll from 'components/InfniteHorizontalScroll/InfiniteHorizontalScroll';
import WCU1 from 'assets/Icons/Home/WhyChooseUs/WCU1';
import { breakpoints, modalIconsItems } from '.';

const BodyContent = ({ idx, cardList, onClose }) => {
  const [currentCard, setCurrentCard] = useState(null);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    setIndex(idx);
    setCurrentCard(cardList[idx]);
  }, []);
  useEffect(() => {
    setCurrentCard(cardList[index]);
  }, [index]);
  return (
    <div className="flex h-[100lvh] w-[100vw] md:w-auto overflow-y  md:h-[740px] bg-white">
      <div className="w-full h-full flex flex-col md:flex-row">
        <div className="h-full w-[100vw] md:w-[760px] relative">
          <div
            onClick={onClose}
            className="absolute bg-neutral-700 opacity-50 p-[5px] rounded-full top-[30px] z-[99999] cursor-pointer right-[10px]"
          >
            <Close />
          </div>
          <CustomSwiper
            showButton
            breakpoints={breakpoints}
            paginated={false}
            swiperSlideContent={currentCard?.details?.images?.map((item) => (
              <SwiperSlide>
                <div className="w-full h-full">
                  <img
                    src={item}
                    alt={`swiper-${item}`}
                    className="w-[100vw] md:w-full  md:h-[740px] object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          />
        </div>
        <div className="py-[10px] px-[10px] max-w-[600px] md:py-[20px]  md:pl-[30px] md:pr-[80px] md:overflow-y-scroll  flex flex-col gap-[20px]">
          <h1 className="text-[24px] px-[10px] font-semibold">
            {currentCard?.details?.title}
          </h1>
          <div className="hidden md:flex items-center">
            {modalIconsItems.map(({ Icon }) => (
              <Icon />
            ))}
          </div>
          <div className="w-full md:hidden block">
            <InfiniteHorizontalScroll w="100%" delay={30}>
              <div className="flex items-center">
                {modalIconsItems.map(({ Icon }) => (
                  <Icon />
                ))}
              </div>
            </InfiniteHorizontalScroll>
          </div>

          <button className="w-full bg-primaryC py-[10px] rounded-lg px-[15px] text-white font-semibold text-[20px]">
            Book a Free Session
          </button>
          <div className="flex flex-col gap-[10px]">
            <p className="font-bold">Specifications</p>
            <p className="text-[12px]">Size : {currentCard?.details?.size}</p>
            <p className="text-[12px]">Shape : Straight</p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 items-center max-w-[600px] gap-[15px] w-[fit-content] rounded-lg ">
            {cardList.map(
              (item, itemIndex) =>
                itemIndex !== index &&
                item.type !== 'info' && (
                  <div
                    className="w-[150px] md:w-[200px] overflow-hidden"
                    onClick={() => {
                      setIndex(itemIndex);
                    }}
                  >
                    <img
                      src={item.img}
                      className=" max-w-[150px] md:max-w-[200px] object-cover rounded-lg"
                      alt={`card-${itemIndex}`}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyContent;
