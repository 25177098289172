import React, { useRef, useEffect } from 'react';

const InfiniteScroll = ({ items }) => {
  const scrollContainerRef = useRef(null);
  const scrollContentRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const scrollContent = scrollContentRef.current;

    const handleScroll = () => {
      if (
        scrollContainer.scrollLeft + scrollContainer.clientWidth >=
        scrollContent.clientWidth
      ) {
        // Add new content when reaching the end
        for (let i = 0; i < items.length; i += 1) {
          const newItem = document.createElement('div');
          newItem.className = 'item';
          newItem.textContent = `Item ${scrollContent.children.length + 1}`;
          scrollContent.appendChild(newItem);
        }
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="scroll-container hide-scrollbar" ref={scrollContainerRef}>
      <div className="scroll-content" ref={scrollContentRef}>
        {items.map((item, index) => (
          <div key={index} className="item">
            <img src={item.img} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteScroll;
