import React from 'react';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import { useParams } from 'react-router-dom';
import DesignerForm from 'pages/Home/_components/DesignerForm/DesignerForm';

import { plans } from 'pages/Home/_components/Prices';
import { costOfConstructionData } from '../Home/_components/ConstOfConstructionSwiper/index';

const KnowMoreDynamic = () => {
  useScrollToTopOnRouteChange();

  const params = useParams();

  if (!params.offertype) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <div className="w-5 h-5 border-dashed rounded-md animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="mt-[65px]">
        <div className="relative w-full ">
          {/* <img
            className="w-full md:hidden max-h-[200px] object-cover"
            src={costOfConstructionData[params.offertype]?.preview?.source}
            alt="banner"
          /> */}
          {costOfConstructionData[params.offertype]?.preview?.type ===
          'image' ? (
            <div
              style={{
                background: `url(${
                  costOfConstructionData[params.offertype]?.preview?.source
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                height: 'calc(100vh/1.5)',
              }}
              className="w-full object-cover "
            >
              <div className="w-full h-full backdrop-blur-10 flex flex-row max-lg:items-center max-lg:justify-center backdrop-blur-sm bg-[rgba(0,0,0,0.2)]">
                <div className="self-center drop-shadow-xl max-lg:p-2 p-10 w-full">
                  <div className="w-fit">
                    <h1 className="drop-shadow-md max-lg:text-center text-white text-shadow-lg font-extrabold text-3xl">
                      {costOfConstructionData[params.offertype]?.title}
                    </h1>
                    <p className="drop-shadow-md max-lg:text-center max-lg:w-full text-xl text-white w-[45%] mt-2">
                      {costOfConstructionData[params.offertype]?.content}
                    </p>
                  </div>
                </div>
                <div className="max-lg:hidden self-center mr-10 flex h-full rounded-lg w-[600px]">
                  <DesignerForm
                    w="100%"
                    customStyle={{
                      alignSelf: 'center',
                      backgroundColor: 'white',
                      padding: '10px 5px 30px',
                      borderRadius: '10px',
                      zoom: 1.3,
                      display: 'block',
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                height: 'calc(100vh/1.5)',
              }}
              className="w-full object-cover "
            >
              <video
                autoPlay
                loop
                muted
                src={costOfConstructionData[params.offertype]?.preview?.source}
                alt={`swiper-img-${
                  costOfConstructionData[params.offertype]?.title
                }`}
                className="absolute top-0 left-0 h-full w-full"
              />
              <div className="w-full h-full backdrop-blur-10 flex flex-row max-lg:items-center max-lg:justify-center backdrop-blur-sm bg-[rgba(0,0,0,0.2)]">
                <div className="self-center drop-shadow-xl max-lg:p-2 p-10 w-full">
                  <div className="w-fit">
                    <h1 className="drop-shadow-md max-lg:text-center text-white text-shadow-lg font-extrabold text-3xl">
                      {costOfConstructionData[params.offertype]?.title}
                    </h1>
                    <p className="drop-shadow-md max-lg:text-center max-lg:w-full text-xl text-white w-[45%] mt-2">
                      {costOfConstructionData[params.offertype]?.content}
                    </p>
                  </div>
                </div>
                <div className="max-lg:hidden self-center mr-10 flex h-full rounded-lg w-[600px]">
                  <DesignerForm
                    w="100%"
                    customStyle={{
                      alignSelf: 'center',
                      backgroundColor: 'white',
                      padding: '10px 5px 30px',
                      borderRadius: '10px',
                      zoom: 1.3,
                      display: 'block',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="max-lg:block hidden mt-15 bg-white flex items-center justify-center h-fit rounded-lg w-full shadow-sm ">
        <DesignerForm
          w="100%"
          customStyle={{
            backgroundColor: 'white',
            textAlign: 'center',
            lineHeight: '20px',
            zoom: 1.3,
            padding: '40px',
            display: 'block',
          }}
        />
      </div>

      <div className="w-full bg-[rgb(255,246,244)] py-9 flex flex-col ">
        <h1 className="text-[32px] font-medium text-center">
          Cost Estimation Of A Construction
        </h1>
        <div className="flex flex-col gap-2 w-[50%] pt-5 mx-auto">
          {plans.map(({ id, title, price }) => (
            <div
              key={id}
              className="flex justify-between items-center p-2 bg-[rgb(255,246,244)] border-b-[1px] border-[rgba(243,134,109,0.74)]"
            >
              <h1 className="text-lg font-medium">{title}</h1>
              <div className="flex items-center gap-2">
                <div className="h-1 w-1 bg-primaryC rounded-full" />
                <h1 className="text-lg font-medium">{price}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center bg-[rgb(255,246,244)] p-4 w-full  py-11">
        <h1 className="text-2xl  text-center font-bold mb-8 text-[rgb(31,7,86)]">
          We serve our customers better than others
        </h1>

        <div className="flex flex-col md:flex-row justify-center items-center gap-8 mb-6">
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4 underline text-center">
              Febit
            </h2>
            <ul className="list-none space-y-2 flex flex-col items-start text-[#1F0756]">
              <li>✅ ESCROW Facility for 100% Money Safety</li>
              <li>✅ Top Quality Assurance with QASCON</li>
              <li>✅ Monitor The Progress From Home using MiddleSection</li>
              <li>✅ ZERO Cost Overruns</li>
              <li>✅ Guaranteed On Time Delivery</li>
            </ul>
          </div>
          <div className="flex flex-1 border border-dashed border-[#F05A29] h-[200px]" />
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4 underline text-center text-[#1F0756]">
              Contractors
            </h2>
            <ul className="list-none space-y-2 flex flex-col items-start">
              <li>❌ No Safety of Money & High Risk</li>
              <li>❌ Improper quality checks</li>
              <li>❌ No MiddleSection. Have to go to construction site</li>
              <li>❌ Costs keep increasing</li>
              <li>❌ No guarantee on timely delivery</li>
            </ul>
          </div>
        </div>

        <h2 className="text-center text-xl text-[#1F065A]">
          Experience Tech-Enabled Construction with us
        </h2>
        <button className="mt-7 bg-orange-600 text-white font-bold py-2 px-4 rounded  hover:bg-orange-700 duration-200 h-12">
          Start Your Construction
        </button>
      </div>

      <div className="flex flex-col gap-[20px] pt-20 max-lg:pt-2">
        <h1 className="font-semibold text-[24px] text-center">
          What includes in: {costOfConstructionData[params.offertype]?.title} ?
        </h1>
        <div className="w-full md:w-[80%] m-auto grid grid-rows-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] pt-[20px]">
          {costOfConstructionData[params.offertype]?.detailedInfo?.map(
            (item, index) =>
              item.type !== 'info' ? (
                <div className="h-fit flex overflow-hidden flex-col w-full gap-[10px] bg-white md:rounded-[20px] shadow-2">
                  <div className="w-full h-[250px]">
                    <img
                      //   onClick={() => {
                      //     setIdx(index);
                      //     onOpen();
                      //   }}
                      src={item.image}
                      className="w-full h-full object-cover"
                      alt={`card-img-${item.title}`}
                    />
                  </div>
                  <div className="p-[15px] flex items-center justify-between gap-4 pb-6 px-4">
                    <div>
                      <h3 className="text-[18px] mb-3 font-medium">
                        {item.title}
                      </h3>
                      <p className="text-[16px] leading-[130%]">
                        {item.description}
                      </p>
                    </div>
                    <button className="rounded-[10px] text-primaryC border-[1px] border-primaryC py-[5px] px-[10px] whitespace-nowrap self-end">
                      Get Quote
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex h-full py-[30px] justify-center gap-[20px] flex-col items-center px-[20px] shadow-2 rounded-lg justify-between gap-4">
                  <div className="w-full px-2">
                    <h3 className="font-semibold text-[20px] mb-3">
                      {item.title}
                    </h3>
                    <p className="leading-[130%] text-[16px] text-neutral-700 font-[400] tracking-[1px] text-left ">
                      {item.description}
                    </p>
                  </div>
                  <button className="text-white mt-[20px] bg-primaryC py-[10px] px-[20px] rounded-[20px]">
                    Get Insights Now
                  </button>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default KnowMoreDynamic;
