import React from 'react';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import CustomSwiper from 'components/Swiper/Swiper';
import { SwiperSlide } from 'swiper/react';
import Banner from './_components/Banner';
import CardList from './_components/CardList/CardList';
import { ourPartners } from '.';

const Design = () => {
  useScrollToTopOnRouteChange();

  return (
    <>
      <Banner />
      <div className="flex flex-col gap-[15px] mt-[25px]">
        <h1 className="text-center font-medium text-[20px]">Our Projects</h1>
        <CardList />
      </div>

      <div className="w-[95%]  m-auto md:pt-[50px] flex flex-col gap-[5px]">
        <h1 className="text-center text-[24px] text-neutral-700 font-medium">
          Our Partners
        </h1>
        <CustomSwiper
          swiperSlideContent={ourPartners.map((item) => (
            <SwiperSlide>
              <div>
                <img
                  src={item.img}
                  alt={`partner-${item.img}`}
                  className=" w-[100px] md:w-[175px] grayscale-[100] hover:grayscale-0 object-conta"
                />
              </div>
            </SwiperSlide>
          ))}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
              slidesPerGroup: 1,
              delay: 1000,
              spaceBetween: 10,
            },

            1024: {
              slidesPerView: 3,
              slidesPerGroup: 1,
              delay: 1000,
              spaceBetween: 20,
            },
            1224: {
              slidesPerView: 6,
              slidesPerGroup: 1,
              delay: 1000,
              spaceBetween: 20,
            },
          }}
        />
      </div>
    </>
  );
};

export default Design;
