export const costOfConstructionData = {
  residential_construction: {
    title: 'Residential Construction',
    preview: {
      type: 'image',
      source:
        'https://media.istockphoto.com/id/1406077905/photo/new-house-building-at-the-construction-site.jpg?s=612x612&w=0&k=20&c=DUpuARwIdKNEmt--VUNnFrb0s0AjiSWvyX85PhsdlcE=',
    },
    content: 'Cost-effective solutions for your dream home.',
    detailedInfo: [
      {
        type: 'info',
        title: 'Single-Family Homes',
        description:
          'Comprehensive cost estimates for single-family home construction, including material, labor, and overhead costs.',
      },
      {
        type: 'info',
        title: 'Multi-Family Homes',
        description:
          'Cost analysis for multi-family homes, such as duplexes, townhouses, and condominiums.',
      },
      {
        type: 'info',
        title: 'Renovations and Additions',
        description:
          'Cost estimates for home renovations, remodeling projects, and additions to existing structures.',
      },
    ],
  },
  commercial_construction: {
    title: 'Commercial Construction',
    preview: {
      type: 'image',
      source:
        'https://www.designblendz.com/hubfs/how-to-get-started-commercial-construction-project.jpg',
    },
    content: 'Tailored cost solutions for your business needs.',
    detailedInfo: [
      {
        type: 'info',
        title: 'Office Buildings',
        description:
          'Cost projections for office building construction, including shell and core, as well as tenant improvements.',
      },
      {
        type: 'infoWithCard',
        image:
          'https://media.istockphoto.com/id/637810078/photo/new-commercial-building-under-construction.jpg?s=612x612&w=0&k=20&c=GzM73PS_MmjfkbME37HoKxuPNBpTVKDBxNcc8kFf0K4=',
        title: 'Retail and Hospitality',
        description:
          'Cost estimates for retail stores, restaurants, hotels, and other hospitality-related construction projects.',
      },
      {
        type: 'info',
        title: 'Industrial Facilities',
        description:
          'Cost analysis for industrial facilities, such as warehouses, manufacturing plants, and distribution centers.',
      },
    ],
  },
  infrastructure_projects: {
    title: 'Infrastructure Projects',
    preview: {
      type: 'image',
      source:
        'https://marathon.in/wp-content/uploads/2022/05/blog_header-07-scaled.jpg',
    },
    content: 'Precise cost estimates for critical infrastructure development.',
    detailedInfo: [
      {
        type: 'info',
        title: 'Roadways and Bridges',
        description:
          'Cost projections for the construction of roads, highways, bridges, and related infrastructure.',
      },
      {
        type: 'info',
        title: 'Water and Sewage Systems',
        description:
          'Cost analysis for water supply, treatment, and distribution systems, as well as sewage and wastewater management facilities.',
      },
      {
        type: 'info',
        title: 'Renewable Energy Projects',
        description:
          'Cost estimates for the development of renewable energy sources, such as solar farms, wind turbines, and hydroelectric plants.',
      },
    ],
  },
};
