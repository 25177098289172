import SS1 from 'assets/Images/Home/SwiperSection1/SS1.jpg';
import SS2 from 'assets/Images/Home/SwiperSection1/cardImg1.webp';
import SS3 from 'assets/Images/Home/SwiperSection1/cardImg3.webp';
import SS4 from 'assets/Images/Home/SwiperSection1/cardImg4.webp';
import kcardImg1 from 'assets/Images/KitchenDesign/cardImg1.jpg';
import kcardImg2 from 'assets/Images/KitchenDesign/cardImg2.webp';
import kcardImg3 from 'assets/Images/KitchenDesign/cardImg3.webp';
import kcardImg4 from 'assets/Images/KitchenDesign/cardImg4.webp';
import wcardImg1 from 'assets/Images/WardrobeDesign/cardImg1.jpg';
import wcardImg2 from 'assets/Images/WardrobeDesign/cardImg2.webp';
import wcardImg3 from 'assets/Images/WardrobeDesign/cardImg3.jpg';
import bcardImg1 from 'assets/Images/BedroomDesign/cardImg1.jpg';
import bcardImg2 from 'assets/Images/BedroomDesign/cardImg2.webp';

// Function to generate a random size string
export function getRandomSize() {
  const sizes = ['4', '6', '8', '10']; // Example sizes
  return `${sizes[Math.floor(Math.random() * sizes.length)]} sq. ft`;
}

// Function to shuffle an array
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

// Shuffle the images array to get random three images
const shuffledImages = shuffleArray([wcardImg1, wcardImg2, wcardImg3]);
console.log('shuffle', shuffledImages);
export const swiperContent1 = [
  {
    title: 'title',
    img: SS1,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS2,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS3,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS4,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS1,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS1,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS1,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS1,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: SS1,
    details: {
      title: 'title',
      images: shuffleArray([SS1, SS2, SS3, SS4]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
];
export const swiperContent2 = [
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
  {
    title: 'title',
    img: 'https://super.homelane.com/Nera Bathroom/1632300235527e073fde6423e-HLKT00000748_12-main.jpg',
  },
];

// Update the wardrobeSwiperSection array
export const wardrobeSwiperSection = [
  {
    title: 'title',
    img: wcardImg3,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg2,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg1,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg3,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg1,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg1,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg3,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg2,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: wcardImg3,
    details: {
      title: 'title',
      images: shuffledImages.slice(0, 3),
      size: getRandomSize(),
    },
  },
];

export const swiperKitchenSection = [
  {
    title: 'title',
    img: kcardImg3,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg2,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg4,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg3,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg2,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: kcardImg4,
    details: {
      title: 'title',
      images: shuffleArray([kcardImg1, kcardImg2, kcardImg3, kcardImg4]).slice(
        0,
        3
      ), // Example shuffled images
      size: getRandomSize(),
    },
  },
];

// Update the swiperBedroomSection array
export const swiperBedroomSection = [
  {
    title: 'title',
    img: bcardImg2,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg2,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg2,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg2,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
  {
    title: 'title',
    img: bcardImg1,
    details: {
      title: 'title',
      images: shuffleArray([bcardImg1, bcardImg2]).slice(0, 3), // Example shuffled images
      size: getRandomSize(),
    },
  },
];
