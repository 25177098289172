import React from 'react';

const KitchenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="76"
    viewBox="0 0 90 76"
    fill="none"
  >
    <path
      d="M37.4776 23.5956H10.0655V4.18401H37.4776V23.5956Z"
      fill="#F05A29"
    />
    <path
      d="M37.8853 72.0461C37.4013 72.0461 37.0085 71.6537 37.0085 71.1693V4.59287C37.0085 4.10846 37.4013 3.71606 37.8853 3.71606C38.3693 3.71606 38.7621 4.10846 38.7621 4.59287V71.1693C38.7621 71.6537 38.3693 72.0461 37.8853 72.0461Z"
      fill="#333333"
    />
    <path
      d="M58.3192 24.1656C57.8352 24.1656 57.4424 23.7732 57.4424 23.2888V4.13681C57.4424 3.65281 57.8352 3.26001 58.3192 3.26001C58.8032 3.26001 59.1956 3.65281 59.1956 4.13681V23.2888C59.1956 23.7732 58.8032 24.1656 58.3192 24.1656Z"
      fill="#333333"
    />
    <path
      d="M58.4055 72.502C57.9215 72.502 57.5287 72.1096 57.5287 71.6252V47.4572C57.5287 46.9732 57.9215 46.5804 58.4055 46.5804C58.8895 46.5804 59.2823 46.9732 59.2823 47.4572V71.6252C59.2823 72.1096 58.8895 72.502 58.4055 72.502Z"
      fill="#333333"
    />
    <path
      d="M58.4055 60.387H11.5095C11.0255 60.387 10.6331 59.9946 10.6331 59.5102C10.6331 59.0262 11.0255 58.6338 11.5095 58.6338H58.4055C58.8895 58.6338 59.2823 59.0262 59.2823 59.5102C59.2823 59.9946 58.8895 60.387 58.4055 60.387Z"
      fill="#333333"
    />
    <path
      d="M11.6532 22.4125H77.8828L78.1628 22.3273V5.03729H11.6532V22.4125ZM11.6532 71.5469H78.1628V47.8781H11.6532V71.5469ZM79.0396 73.3001H10.7764C10.2924 73.3001 9.90001 72.9077 9.90001 72.4237V4.16049C9.90001 3.67649 10.2924 3.28369 10.7764 3.28369H79.0396C79.524 3.28369 79.9164 3.67649 79.9164 4.16049V22.9765C79.9164 23.3621 79.664 23.7025 79.2952 23.8149L78.2692 24.1277C78.1864 24.1529 78.1 24.1657 78.0136 24.1657H11.6532V46.1245H78.4696C78.61 46.1245 78.7484 46.1585 78.8732 46.2229L79.4432 46.5185C79.7336 46.6693 79.9164 46.9693 79.9164 47.2969V72.4237C79.9164 72.9077 79.524 73.3001 79.0396 73.3001Z"
      fill="#333333"
    />
    <path d="M67.8123 11.7598H71.2483V15.1998H67.8123V11.7598Z" fill="black" />
    <path
      d="M71.2499 13.4797C71.2499 14.4289 70.4803 15.1985 69.5311 15.1985C68.5819 15.1985 67.8127 14.4289 67.8127 13.4797C67.8127 12.5305 68.5819 11.7609 69.5311 11.7609C70.4803 11.7609 71.2499 12.5305 71.2499 13.4797Z"
      fill="#0178B5"
    />
    <path d="M46.6203 12.1077H50.0564V15.5477H46.6203V12.1077Z" fill="black" />
    <path
      d="M50.0564 13.8272C50.0564 14.7764 49.2868 15.546 48.3376 15.546C47.3884 15.546 46.6192 14.7764 46.6192 13.8272C46.6192 12.878 47.3884 12.1084 48.3376 12.1084C49.2868 12.1084 50.0564 12.878 50.0564 13.8272Z"
      fill="#0178B5"
    />
    <path
      d="M19.1759 52.4337H29.5539V54.2605H19.1759V52.4337Z"
      fill="#FEC666"
    />
    <path
      d="M19.1759 65.2889H29.5539V67.1157H19.1759V65.2889Z"
      fill="#FEC666"
    />
    <path
      d="M64.3198 46.9344C63.8358 46.9344 63.443 46.542 63.443 46.0576V43.86C63.443 43.376 63.8358 42.9836 64.3198 42.9836C64.8038 42.9836 65.1962 43.376 65.1962 43.86V46.0576C65.1962 46.542 64.8038 46.9344 64.3198 46.9344Z"
      fill="#333333"
    />
    <path
      d="M72.4757 46.9344C71.9917 46.9344 71.5993 46.542 71.5993 46.0576V43.86C71.5993 43.376 71.9917 42.9836 72.4757 42.9836C72.9597 42.9836 73.3525 43.376 73.3525 43.86V46.0576C73.3525 46.542 72.9597 46.9344 72.4757 46.9344Z"
      fill="#333333"
    />
    <path
      d="M72.1896 43.5409H63.706C61.0912 43.5409 58.9644 41.4137 58.9644 38.7993V36.1789C58.9644 35.6945 59.3568 35.3021 59.8408 35.3021C60.3252 35.3021 60.7176 35.6945 60.7176 36.1789V38.7993C60.7176 40.4473 62.058 41.7877 63.706 41.7877H72.1896C73.8372 41.7877 75.178 40.4473 75.178 38.7993V36.1789C75.178 35.6945 75.5704 35.3021 76.0544 35.3021C76.5388 35.3021 76.9312 35.6945 76.9312 36.1789V38.7993C76.9312 41.4137 74.804 43.5409 72.1896 43.5409Z"
      fill="#333333"
    />
  </svg>
);

export default KitchenIcon;
