import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import Logo from 'assets/Icons/logo_brand.jpg';
import { sidebarLinks1 } from '.';
import Dropdown from './_legos/Dropdown/Dropdown';
import NavRight from './_legos/NavRight/NavRight';

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(null);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  console.log(showSidebar);

  const sidebarVariants = {
    show: {
      x: 0,
      opacity: 1,

      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
    hide: {
      x: '100%',
      opacity: 0,

      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };

  const crossButtonVariants = {
    initial: {
      opacity: 0.2,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };
  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = 'hidden'; // Disable scrolling on the body
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
    }

    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrolling is re-enabled when component unmounts
    };
  }, [showSidebar]);
  return (
    <>
      <div className="bg-white z-[9999999] shadow-1 w-full fixed top-0 left-0 h-[68px] flex items-center px-2">
        <nav className="flex justify-between items-center w-[95%] m-auto">
          <div className="flex items-center gap-[8px]">
            <button onClick={() => setShowSidebar(true)}>
              <Icon icon="ci:hamburger-md" className="w-8 h-6 md:hidden" />
            </button>{' '}
            <Link to="/">
              <img src={Logo} className="w-[140px]" alt="" />
            </Link>
          </div>
          <div
            style={{ zIndex: '99999999' }}
            className={`
            flex flex-col overflow-x-hidden fixed h-[100vh] overflow-scroll
               w-[75%] top-0 ${
                 showSidebar ? 'right-0' : 'right-[-100%]'
               } z-[9999999] bg-white transition-all ease-in duration-300`}
          >
            <div className="flex items-center gap-[5px] ml-[20px] mt-[20px]">
              <div className="" onClick={() => setShowSidebar(false)}>
                <Icon
                  icon="flowbite:arrow-left-outline"
                  size={45}
                  className="h-[35px] w-[25px]"
                />
              </div>
              <h1 className="font-bold text-[28px]">
                <img src={Logo} className="w-[140px]" alt="" />
              </h1>
            </div>
            <div className="pl-[30px] py-[10px] flex flex-col gap-[20px] text-neutral-800 font-[400] text-[15px]">
              {sidebarLinks1.map((item) => (
                <p>{item.title}</p>
              ))}
            </div>
            <div className="pl-[30px] bg-white py-[20px] pb-[100px]  flex flex-col mt-[10px] gap-[20px] text-neutral-800 font-[400] text-[15px">
              {sidebarLinks1.map((item) => (
                <p>{item.title}</p>
              ))}
            </div>
          </div>
          {showSidebar === true && (
            <div
              onClick={() => setShowSidebar(false)}
              className="h-[100vh] w-[100vw] z-[99999] bg-neutral-600 opacity-50 fixed top-0 left-0"
            />
          )}
          <Dropdown />
          {/* <NavRight /> */}
          <button
            onClick={() => {
              navigate('/contact-us');
            }}
            className="hidden md:block py-[5px] px-[10px] rounded-md shadow-2 bg-primaryC text-white text-[14px] font-medium"
          >
            Contact Us
          </button>
        </nav>
      </div>
      {/* <div className="bottom-0 shadow-2 md:hidden py-[10px] h-[65 px] flex items-center justify-center bg-white fixed z-[9999999]  gap-[20px]  w-full m-auto">
        <div className="flex overflow-x-scroll overflow-y-hidden items-end gap-[30px] w-[100%] pl-[20px] m-auto">
          <Link to="/">
            <div className="flex flex-col items-center gap-[0px] ">
              <Icon
                className="w-[35px] h-[25px] "
                icon="material-symbols-light:home"
                style={{ color: pathname === '/' ? '#e76589' : 'grey' }}
              />
              <p
                className="text-[13px] font-medium"
                style={{ color: pathname === '/' ? '#e76589' : 'grey' }}
              >
                Go To Home
              </p>
            </div>
          </Link>
          <Link to="/design">
            <div className="flex flex-col items-center gap-[-5px]">
              {' '}
              <Icon
                className="w-[35px] h-[25px]"
                icon="humbleicons:bulb"
                style={{ color: pathname === '/design' ? '#e76589' : 'grey' }}
              />
              <p
                className="text-[13px] text-center font-medium"
                style={{ color: pathname === '/design' ? '#e76589' : 'grey' }}
              >
                Modular Design
              </p>
            </div>
          </Link>
          <Link to="/design/kitchen">
            <div className="flex flex-col items-center gap-[-5px]">
              {' '}
              <Icon
                className="w-[35px] h-[25px]"
                icon="material-symbols-light:kitchen-outline"
                style={{
                  color: pathname === '/design/kitchen' ? '#e76589' : 'grey',
                }}
              />
              <p
                className="text-[13px] font-medium text-center"
                style={{
                  color: pathname === '/design/kitchen' ? '#e76589' : 'grey',
                }}
              >
                Kitchen Designs
              </p>
            </div>
          </Link>
          <Link to="/design/wardrobe">
            <div className="flex flex-col items-center gap-[-5px]">
              {' '}
              <Icon
                className="w-[35px] h-[25px]"
                icon="mdi:wardrobe"
                style={{
                  color: pathname === '/design/wardrobe' ? '#e76589' : 'grey',
                }}
              />
              <p
                className="text-[13px] font-medium text-center"
                style={{
                  color: pathname === '/design/wardrobe' ? '#e76589' : 'grey',
                }}
              >
                Wardrobe Designs
              </p>
            </div>
          </Link>
          <Link to="/design/bedroom">
            <div className="flex flex-col items-center gap-[-5px]">
              {' '}
              <Icon
                className="w-[35px] h-[25px]"
                icon="mdi:bedroom"
                style={{
                  color: pathname === '/design/bedroom' ? '#e76589' : 'grey',
                }}
              />
              <p
                className="text-[13px] font-medium text-center"
                style={{
                  color: pathname === '/design/bedroom' ? '#e76589' : 'grey',
                }}
              >
                Bedroom Designs
              </p>
            </div>
          </Link>
          <Link to="/contact-us">
            <div className="flex flex-col items-center gap-[2px]">
              {' '}
              <Icon
                className="w-[25px] h-[15px]"
                icon="fa:book"
                style={{
                  color: pathname === '/contact-us' ? '#e76589' : 'grey',
                }}
              />
              <p
                className="text-[13px] font-medium text-center"
                style={{
                  color: pathname === '/contact-us' ? '#e76589' : 'grey',
                }}
              >
                Visit Us
              </p>
            </div>
          </Link>
          <div className="pr-[15px]">
            <button onClick={() => setShowSidebar((prev) => !prev)}>
              {showSidebar ? (
                <motion.button
                  variants={crossButtonVariants}
                  initial="initial"
                  animate="animate"
                >
                  <Icon
                    icon="akar-icons:cross"
                    style={{ color: 'black' }}
                    className="w-[24px] h-6"
                  />
                </motion.button>
              ) : (
                <motion.div
                  variants={crossButtonVariants}
                  initial="initial"
                  animate="animate"
                >
                  <Icon icon="radix-icons:hamburger-menu" className="w-6 h-6" />
                </motion.div>
              )}
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Navbar;
