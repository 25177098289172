import React, { useState } from 'react';

import CustomAccordion from 'components/Accordion/Accordion';
import DesignerFormDesktop from 'pages/Home/_components/DesignerFormDesktop/DesignerFormDesktop';
import useCardModal from 'hooks/useCardModal';
import Modal from 'components/Modal/Modal';
import DesignerForm from 'pages/Home/_components/DesignerForm/DesignerForm';
import CardModal from './_legos/CardModal/CardModal';

const DesignPage = ({
  cardList,
  heading,
  bannerHeading,
  BannerImage,
  BannerDesktop,
}) => {
  const { isOpen, onOpen, onClose } = useCardModal();
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [idx, setIdx] = useState(null);

  return (
    <>
      <div className="mt-[65px]">
        <div className="relative w-full ">
          <img
            className="w-full md:hidden max-h-[200px] object-cover"
            src={BannerImage}
            alt="banner"
          />
          <img
            className="w-full h-[600px] object-cover hidden md:block"
            src={BannerDesktop}
            alt="banner"
          />
          <div className="w-[96%] m-auto flex justify-between items-center absolute left-[50%] translate-x-[-50%] bottom-[15px] md:bottom-[25px] text-white text-[18px]  sm:text-[18px] md:text-[25px] font-bold">
            <h1>{heading}</h1>
            <button
              onClick={() => setFormIsOpen(true)}
              className=" p-[7.5px] sm:p-[10px] block md:hidden bg-primaryC text-white text-[18px] rounded-lg"
            >
              Book Consultation
            </button>
          </div>

          {/* <div className="absolute w-[25%] top-[30%] shadow-2 right-[2%] hidden md:block">
            <DesignerFormDesktop />
          </div> */}
          <div className="absolute hidden md:flex top-[50%] gap-[20px] left-[50%] translate-x-[-50%] translate-y-[-50%]  flex-col items-center text-white text-center w-[60%] text-[50px] font-medium">
            {bannerHeading || <h1>The finest solutions in modular designs</h1>}
            <button
              onClick={() => setFormIsOpen(true)}
              className="min-w-full md:min-w-[30%] bg-primaryC py-[15px] px-[40px] rounded-[35px] shadow-2 text-white font-medium text-[24px]"
            >
              Book Online Consulation
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[20px] pt-[25px] md:pt-[120px]">
        <h1 className="font-semibold text-[24px] text-center">Our Projects</h1>
        <div className="w-full md:w-[80%] m-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-[30px] pt-[20px]">
          {cardList.map((item, index) =>
            item.type !== 'info' ? (
              <div className="h-[330px] flex overflow-hidden flex-col w-full gap-[10px] bg-white md:rounded-[20px] shadow-2">
                <div className="w-full h-[250px]">
                  <img
                    onClick={() => {
                      setIdx(index);
                      onOpen();
                    }}
                    src={item.img}
                    className="w-full h-full object-cover"
                    alt={`card-img-${item.img}`}
                  />
                </div>
                <div className="p-[15px] flex items-center justify-between">
                  <h3 className="text-[18px] font-medium">{item.title}</h3>
                  <button className="rounded-[10px] text-primaryC border-[1px] border-primaryC py-[5px] px-[10px]">
                    Get Quote
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex h-[300px] py-[30px] justify-center gap-[20px]  flex-col items-center px-[20px]">
                <h3 className="font-semibold text-[20px]">TIP : {item.tip}</h3>
                <p className="leading-[130%] text-[16px] text-neutral-700 font-[400 ] tracking-[1px] text-center px-[30px]">
                  Enim laboris esse do cillum officia ea excepteur pariatur
                  consectetur. Mollit mollit duis non consectetur commodo eu
                  amet adipisicing culpa esse deserunt sint. Incididunt laborum
                  Lorem in laboris non do id pariatur voluptate in fugiat id
                  consectetur adipisicing.
                </p>
                <button className="text-white mt-[20px] bg-primaryC py-[10px] px-[20px] rounded-[20px]">
                  Get Insights Now
                </button>
              </div>
            )
          )}
        </div>
      </div>
      <div className="py-[36px] flex flex-col gap-[40px]">
        <h1 className="text-[24px] text-center text-neutral-800 font-semibold">
          Frequently Asked Questions
        </h1>
        <div className="w-[95%] md:w-[70%] m-auto">
          {Array.from({ length: 6 }, (_, index) => (
            <CustomAccordion idx={index} />
          ))}
        </div>
      </div>
      <CardModal
        cardList={cardList}
        idx={idx}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Modal
        isOpen={formIsOpen}
        onClose={() => setFormIsOpen(false)}
        body={
          <>
            <div className="">
              <DesignerFormDesktop w="420px" />
            </div>
            <div className="relative bg-white rounded-md md:hidden p-[10px]">
              <DesignerForm w="300px" />
            </div>
          </>
        }
      />
    </>
  );
};

export default DesignPage;
