import React from 'react';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import { useParams } from 'react-router-dom';
import DesignerForm from 'pages/Home/_components/DesignerForm/DesignerForm';

import { interiorDetails } from '../Home/_components/InteriorsSwiper/index';

const KnowMoreDynamic = () => {
  useScrollToTopOnRouteChange();

  const params = useParams();

  if (!params.offertype) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <div className="w-5 h-5 border-dashed rounded-md animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="mt-[65px]">
        <div className="relative w-full ">
          <div
            style={{
              background: 'rgba(240,90,41, 0.1)',
              height: 'calc(100vh/1.5)',
            }}
            className="w-full object-cover"
          >
            <video
              autoPlay
              loop
              muted
              src={interiorDetails[params.offertype]?.preview?.source}
              alt={`swiper-img-${interiorDetails[params.offertype]?.title}`}
              className="absolute top-0 left-0 h-full w-full"
            />
            <div className="w-full h-full flex flex-row max-lg:items-center max-lg:justify-center bg-[rgba(0,0,0,0.5)]">
              <div className="self-center drop-shadow-xl max-lg:p-2 p-10 w-full">
                <div
                  style={
                    interiorDetails[params.offertype]?.preview?.type === 'video'
                      ? {
                          color: 'white',
                        }
                      : { color: 'white' }
                  }
                  className="w-fit"
                >
                  <h1 className="drop-shadow-md max-lg:text-center text-shadow-lg font-extrabold text-3xl">
                    {interiorDetails[params.offertype]?.title}
                  </h1>
                  <p className="drop-shadow-md max-lg:text-center max-lg:w-full text-xl w-[45%] mt-2">
                    {interiorDetails[params.offertype]?.content}
                  </p>
                </div>
              </div>
              <div className="z-[999] right-0 max-lg:hidden self-center mr-10 flex h-full rounded-lg w-[600px]">
                <DesignerForm
                  w="100%"
                  customStyle={{
                    alignSelf: 'center',
                    backgroundColor: 'white',
                    padding: '10px 5px 30px',
                    borderRadius: '10px',
                    zoom: 1.3,
                    display: 'block',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-lg:block hidden mt-15 bg-white flex items-center justify-center h-fit rounded-lg w-full shadow-sm ">
        <DesignerForm
          w="100%"
          customStyle={{
            backgroundColor: 'white',
            textAlign: 'center',
            lineHeight: '20px',
            zoom: 1.3,
            padding: '40px',
            display: 'block',
          }}
        />
      </div>

      <div className="flex flex-col gap-[20px] pt-20 max-lg:pt-2">
        <h1 className="font-semibold text-[24px] text-center">
          What includes in: {interiorDetails[params.offertype]?.title} ?
        </h1>
        <div className="w-full md:w-[80%] m-auto grid grid-rows-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] pt-[20px]">
          {interiorDetails[params.offertype]?.detailedInfo?.map((item, index) =>
            item.type !== 'info' ? (
              <div className="h-fit flex overflow-hidden flex-col w-full gap-[10px] bg-white md:rounded-[20px] shadow-2">
                <div className="w-full h-[250px]">
                  <img
                    //   onClick={() => {
                    //     setIdx(index);
                    //     onOpen();
                    //   }}
                    src={item.image}
                    className="w-full h-full object-cover"
                    alt={`card-img-${item.title}`}
                  />
                </div>
                <div className="p-[15px] flex items-center justify-between gap-4 pb-6 px-4">
                  <div>
                    <h3 className="text-[18px] mb-3 font-medium">
                      {item.title}
                    </h3>
                    <p className="text-[16px] leading-[130%]">
                      {item.description}
                    </p>
                  </div>
                  <button className="rounded-[10px] text-primaryC border-[1px] border-primaryC py-[5px] px-[10px] whitespace-nowrap self-end">
                    Get Quote
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex h-full py-[30px] justify-center gap-[20px] flex-col items-center px-[20px] shadow-2 rounded-lg justify-between gap-4">
                <div className="w-full px-2">
                  <h3 className="font-semibold text-[20px] mb-3">
                    {item.title}
                  </h3>
                  <p className="leading-[130%] text-[16px] text-neutral-700 font-[400] tracking-[1px] text-left ">
                    {item.description}
                  </p>
                </div>
                <button className="text-white mt-[20px] bg-primaryC py-[10px] px-[20px] rounded-[20px]">
                  Get Insights Now
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default KnowMoreDynamic;
