import CalcBox from 'assets/Icons/Home/Estimates/CalcBox';
import HomeIcon from 'assets/Icons/Home/Estimates/HomeIcon';
import KitchenIcon from 'assets/Icons/Home/Estimates/KitchenIcon';
import React from 'react';

const Estimates = () => (
  <div className="flex flex-col md:py-[30px]  gap-[40px] w-[90%] m-auto">
    <div className="flex flex-col gap-[5px] text-center m-auto">
      <h1 className="text-[20px] font-semibold">
        Get an Estimate for you <span className="text-red-500">Home</span>
      </h1>
      <p className="text-[12px] font-[400] w-[70%] m-auto">
        Calculate cost of doing up your interiors now
      </p>
    </div>
    <div className="flex flex-col md:flex-row gap-[30px] w-full">
      <div className="w-full p-[20px] shadow-1 flex items-start justify-between">
        <div className="w-[75%] flex flex-col gap-[8px]">
          <HomeIcon />
          <h3 className="font-medium text-[18px]">Full Home Interiors</h3>
          <p>Get the estimate price for full home interiors</p>
          <button className="bg-bgPrimary py-[10px] px-[20px] rounded-md shadow-1 text-white font-semibold">
            Get Free Estimate
          </button>
        </div>
        <div>
          <CalcBox />
        </div>
      </div>
      <div className="w-full p-[20px] shadow-1 flex items-start justify-between">
        <div className="w-[75%] flex flex-col gap-[8px]">
          <KitchenIcon />
          <h3 className="font-medium text-[18px]">Full Home Interiors</h3>
          <p>Get the estimate price for full home interiors</p>
          <button className="bg-bgPrimary py-[10px] px-[20px] rounded-md shadow-1 text-white font-semibold">
            Get Free Estimate
          </button>
        </div>
        <div>
          <CalcBox />
        </div>
      </div>
    </div>
  </div>
);

export default Estimates;
