import Comp1 from 'assets/Images/Home/Partners/jsp-panther.png';
import Comp2 from 'assets/Images/Home/Partners/jsw-neo-steel.png';
import Comp3 from 'assets/Images/Home/Partners/jsw-paint.png';
import Comp4 from 'assets/Images/Home/Partners/bhuwalka.png';
import Comp5 from 'assets/Images/Home/Partners/chettinad.png';

export const ourPartners = [
  {
    img: Comp2,
  },
  {
    img: Comp4,
  },
  {
    img: Comp2,
  },

  {
    img: Comp1,
  },
  {
    img: Comp3,
  },
  {
    img: Comp5,
  },
  {
    img: Comp2,
  },

  {
    img: Comp1,
  },
  {
    img: Comp3,
  },
  {
    img: Comp5,
  },
];
