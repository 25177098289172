import React from 'react';

const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="76"
    viewBox="0 0 90 76"
    fill="none"
  >
    <path
      d="M44.1529 31.4243L26.1886 31.4312L38.6586 18.9612L44.0316 24.3342L44.1529 31.4243Z"
      fill="#8BBFE4"
    />
    <path
      d="M57.4625 31.6557L33.7571 31.6647L50.2122 15.2092L57.3026 22.2997L57.4625 31.6557Z"
      fill="#0178B5"
    />
    <path
      d="M36.2651 55.5095C35.7411 55.5095 35.316 55.0847 35.316 54.5604V47.7741C35.316 43.7136 32.0126 40.4102 27.952 40.4102H13.87C9.80948 40.4102 6.50604 43.7136 6.50604 47.7741V54.5604C6.50604 55.0847 6.08093 55.5095 5.55695 55.5095C5.0326 55.5095 4.60785 55.0847 4.60785 54.5604V47.7741C4.60785 42.6671 8.76295 38.512 13.87 38.512H27.952C33.0591 38.512 37.2142 42.6671 37.2142 47.7741V54.5604C37.2142 55.0847 36.7891 55.5095 36.2651 55.5095Z"
      fill="#333333"
    />
    <path
      d="M66.9866 55.5095C66.4626 55.5095 66.0375 55.0847 66.0375 54.5604V47.7741C66.0375 43.7136 62.7341 40.4102 58.6732 40.4102H44.5919C40.531 40.4102 37.2275 43.7136 37.2275 47.7741C37.2275 48.2985 36.8024 48.7232 36.2784 48.7232C35.7541 48.7232 35.3293 48.2985 35.3293 47.7741C35.3293 42.6671 39.4844 38.512 44.5919 38.512H58.6732C63.7806 38.512 67.9357 42.6671 67.9357 47.7741V54.5604C67.9357 55.0847 67.5106 55.5095 66.9866 55.5095Z"
      fill="#333333"
    />
    <path
      d="M1.89819 67.8019H9.59165V59.5354C9.59165 57.4145 7.86596 55.6888 5.74474 55.6888C3.62389 55.6888 1.89819 57.4145 1.89819 59.5354V67.8019ZM10.5408 69.7001H0.949097C0.425109 69.7001 0 69.275 0 68.751V59.5354C0 56.368 2.577 53.7906 5.74474 53.7906C8.91249 53.7906 11.4899 56.368 11.4899 59.5354V68.751C11.4899 69.275 11.0647 69.7001 10.5408 69.7001Z"
      fill="#333333"
    />
    <path
      d="M63.2089 67.8019H70.9024V59.5354C70.9024 57.4145 69.1767 55.6888 67.0554 55.6888C64.9346 55.6888 63.2089 57.4145 63.2089 59.5354V67.8019ZM71.8515 69.7001H62.2598C61.7358 69.7001 61.3107 69.275 61.3107 68.751V59.5354C61.3107 56.368 63.8877 53.7906 67.0554 53.7906C70.2232 53.7906 72.8005 56.368 72.8005 59.5354V68.751C72.8005 69.275 72.3754 69.7001 71.8515 69.7001Z"
      fill="#333333"
    />
    <path
      d="M78.5608 67.6138H86.0175C85.5935 65.9501 84.0829 64.7159 82.289 64.7159C80.4954 64.7159 78.9844 65.9501 78.5608 67.6138ZM87.085 69.512H77.4933C76.9693 69.512 76.5442 69.0868 76.5442 68.5629C76.5442 65.3951 79.1212 62.8181 82.289 62.8181C85.4567 62.8181 88.0341 65.3951 88.0341 68.5629C88.0341 69.0868 87.609 69.512 87.085 69.512Z"
      fill="#333333"
    />
    <path
      d="M62.2598 60.396C61.7358 60.396 61.3107 59.9712 61.3107 59.4469C61.3107 57.3257 59.585 55.6 57.4638 55.6H15.3368C13.2156 55.6 11.4899 57.3257 11.4899 59.4469C11.4899 59.9712 11.0648 60.396 10.5408 60.396C10.0165 60.396 9.59174 59.9712 9.59174 59.4469C9.59174 56.2791 12.1691 53.7018 15.3368 53.7018H57.4638C60.6315 53.7018 63.2089 56.2791 63.2089 59.4469C63.2089 59.9712 62.7838 60.396 62.2598 60.396Z"
      fill="#333333"
    />
    <path
      d="M61.9777 65.6095H10.917C10.393 65.6095 9.9679 65.1847 9.9679 64.6604C9.9679 64.1364 10.393 63.7113 10.917 63.7113H61.9777C62.5017 63.7113 62.9268 64.1364 62.9268 64.6604C62.9268 65.1847 62.5017 65.6095 61.9777 65.6095Z"
      fill="#333333"
    />
    <path
      d="M16.2854 30.5173H56.1388V8.53075H16.2854V30.5173ZM57.0878 32.4152H15.3363C14.8123 32.4152 14.3875 31.9904 14.3875 31.4661V7.5813C14.3875 7.05731 14.8123 6.6322 15.3363 6.6322H57.0878C57.6118 6.6322 58.0369 7.05731 58.0369 7.5813V31.4661C58.0369 31.9904 57.6118 32.4152 57.0878 32.4152Z"
      fill="#333333"
    />
    <path
      d="M82.2892 64.7159C81.7652 64.7159 81.3401 64.2911 81.3401 63.7668V31.2777C81.3401 30.7537 81.7652 30.329 82.2892 30.329C82.8131 30.329 83.2383 30.7537 83.2383 31.2777V63.7668C83.2383 64.2911 82.8131 64.7159 82.2892 64.7159Z"
      fill="#333333"
    />
    <path
      d="M89.9999 31.2782H74.5784L77.4278 14.3896H87.1505L89.9999 31.2782Z"
      fill="#F05A29"
    />
  </svg>
);

export default HomeIcon;
