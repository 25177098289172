import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { dropdownLinks, dropdownVariants } from '.';

const Dropdown = () => {
  const [showDropdown, setShowDropdown] = useState(null);

  return (
    <div className="hidden">
      <button
        onClick={() => setShowDropdown(true)}
        className="px-[15px] py-[7px] bg-bgGold text-neutral-800 font-semibold text-[12px]"
      >
        Design Gallery
      </button>

      <motion.div
        className={`${showDropdown === false && 'nav-items-shrink'} ${
          showDropdown === true && 'nav-items'
        }  scale-0 absolute z-[9999999] top-[120%] right-[-10%] translate-x-[50%] w-[max-content] bg-white`}
      >
        {dropdownLinks.map((item) => (
          <div className="py-[10px] px-[10px] border-b-[1px] border-neutral-200">
            <Link to={item.link} className="text-[13px]">
              <p>{item.title}</p>
            </Link>
          </div>
        ))}
      </motion.div>

      {showDropdown && (
        <div
          onClick={() => setShowDropdown(false)}
          className="fixed h-[100vh] w-[100vw] bg-neutral-900 bg-opacity-10 top-0 left-0 z-[999999]"
        />
      )}
    </div>
  );
};

export default Dropdown;
