export const sidebarLinks1 = [
  {
    title: 'Modular Designs',
  },
  {
    title: 'Kitchen Designs',
  },
  {
    title: 'Wardrboe Designs',
  },
];
