export const dropdownLinks = [
  {
    title: 'Kitchen Designs',
    link: '/design/kitchen',
  },
  {
    title: 'Wardrobe Designs',
    link: '/design/wardrobe',
  },
  {
    title: 'Bedroom Designs',
    link: '/design/bedroom',
  },
  {
    title: 'Wardrobe Designs',
    link: '/design/wardrobe',
  },
  {
    title: 'Kitchen Designs',
    link: '/design/kitchen',
  },
  {
    title: 'Wardrobe Designs',
    link: '/design/wardrobe',
  },
];

export const dropdownVariants = {
  initial: {
    scaleY: 0,
  },
  animate: {
    scaleY: '100%',
    transition: {
      duration: 0.5,
      origin: 'top center',
    },
  },
};
