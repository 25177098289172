export const plans = [
  {
    id: 2,
    title: 'Standard',
    price: 'Rs. 1599',
  },
  {
    id: 3,
    title: 'Premium',
    price: 'Rs. 1799',
  },
  {
    id: 4,
    title: 'Royale',
    price: 'Rs. 2199',
  },
];
