import cardImg1 from 'assets/Images/KitchenDesign/cardImg1.jpg';
import cardImg2 from 'assets/Images/KitchenDesign/cardImg2.webp';
import cardImg3 from 'assets/Images/KitchenDesign/cardImg3.webp';

export const cardList = [
  {
    title: 'Card Title',
    img: cardImg1,
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [
        cardImg3,
        cardImg2,
        'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
      ],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: cardImg2,
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg2, cardImg1, cardImg3],
      size: '4 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg3, cardImg2],
      size: '7 sq. ft',
    },
  },
  {
    title: 'Card Title',
    type: 'info',
    tip: 'Perfect module design',
    details: {
      title: 'Card title',
      images: [cardImg2, cardImg1, cardImg3],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg3, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: cardImg2,
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg2, cardImg1, cardImg1],
      size: '2 sq. ft',
    },
  },
  {
    title: 'Card Title',
    type: 'info',
    tip: 'Perfect module design',
    details: {
      title: 'Card title',
      images: [cardImg1, cardImg1, cardImg3],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    img: 'https://super.homelane.com/Kitchens and Wardrobes/1680591635736e20b57ed5dbe-HLKT00000781_Resized-800x600_6-main.jpg',
    type: 'cardImage',
    details: {
      title: 'Card title',
      images: [cardImg2, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
  {
    title: 'Card Title',
    type: 'info',
    tip: 'Perfect module design',
    details: {
      title: 'Card title',
      images: [cardImg3, cardImg1, cardImg1],
      size: '6 sq. ft',
    },
  },
];
