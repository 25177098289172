import Video1 from 'assets/Images/Home/InteriorSwiper/video1.mp4';
import Video2 from 'assets/Images/Home/InteriorSwiper/video2.mp4';
import Video3 from 'assets/Images/Home/InteriorSwiper/video3.mp4';
import Video4 from 'assets/Images/Home/InteriorSwiper/video4.mp4';
import Video5 from 'assets/Images/Home/InteriorSwiper/videor5.mp4';
import Video6 from 'assets/Images/Home/InteriorSwiper/video6.mp4';

export const interiorDetails = {
  living_room: {
    title: 'Living Room',
    preview: {
      type: 'video',
      source: Video1,
    },
    content: 'Cozy and Inviting Living Room Designs',
    detailedInfo: [
      {
        type: 'info',
        title: 'Furniture Selection',
        description:
          "Our designers will help you choose comfortable and stylish furniture pieces that complement your living room's aesthetic.",
      },
      {
        type: 'info',
        title: 'Color Schemes',
        description:
          "We'll work with you to select a harmonious color palette that creates a warm and welcoming atmosphere in your living room.",
      },
      {
        type: 'info',
        title: 'Lighting Design',
        description:
          "Proper lighting is crucial in creating the right ambiance. We'll incorporate task and ambient lighting for a well-lit and inviting living space.",
      },
    ],
  },
  kitchen: {
    title: 'Kitchen',
    preview: {
      type: 'video',
      source: Video2,
    },
    content: 'Functional and Stylish Kitchen Designs',
    detailedInfo: [
      {
        type: 'info',
        title: 'Layout Optimization',
        description:
          'Our kitchen designers will create an efficient layout that maximizes counter space and optimizes workflow.',
      },
      {
        type: 'info',
        title: 'Appliance Selection',
        description:
          "We'll help you choose high-quality appliances that not only look great but also meet your cooking and storage needs.",
      },
      {
        type: 'info',
        title: 'Storage Solutions',
        description:
          'Keep your kitchen organized and clutter-free with our innovative storage solutions, including custom cabinetry and pantry designs.',
      },
    ],
  },
  bedroom: {
    title: 'Bedroom',
    preview: {
      type: 'video',
      source: Video3,
    },
    content: 'Serene and Restful Bedroom Designs',
    detailedInfo: [
      {
        type: 'info',
        title: 'Bed and Furniture Selection',
        description:
          'Create a cozy and inviting bedroom with our carefully curated selection of beds, dressers, and night stands.',
      },
      {
        type: 'info',
        title: 'Closet Organization',
        description:
          'Stay organized with our custom closet solutions, designed to maximize storage space and keep your belongings neatly arranged.',
      },
      {
        type: 'info',
        title: 'Lighting and Ambiance',
        description:
          'Set the mood for relaxation with our expertly designed lighting and ambiance solutions, creating a tranquil and peaceful bedroom environment.',
      },
    ],
  },
  wardrobe: {
    title: 'Wardrobe',
    preview: {
      type: 'video',
      source: Video4,
    },
    content: 'Organized and Stylish Wardrobe Designs',
    detailedInfo: [
      {
        type: 'infoWithCard',
        image:
          'https://media.designcafe.com/wp-content/uploads/2022/04/01164258/elegant-white-wardrobe-designs-for-bedroom.jpg',
        title: 'Custom Wardrobe Solutions',
        description:
          'Our custom wardrobe solutions are tailored to your specific needs, providing ample storage space and easy access to your clothing and accessories.',
      },
      {
        type: 'info',
        title: 'Organization and Functionality',
        description:
          'Stay organized with our innovative wardrobe features, including built-in shelves, hanging rods, and drawer organizers.',
      },
      {
        type: 'info',
        title: 'Aesthetic Appeal',
        description:
          'Our wardrobe designs not only provide functionality but also add aesthetic appeal to your bedroom or dressing area.',
      },
    ],
  },
};
