import CardImg1 from 'assets/Images/Design/project1.jpg';
import CardImg2 from 'assets/Images/Design/project2.jpg';
import CardImg3 from 'assets/Images/Design/project3.jpg';

export const cardListItems = [
  {
    img: CardImg1,
    size: '25 sq. feet',
    desc: "This is the project's description",
  },
  {
    img: CardImg2,
    size: '25 sq. feet',
    desc: "This is the project's description",
  },
  {
    img: CardImg3,
    size: '21 sq. feet',
    desc: "This is the project's description",
  },
  {
    img: CardImg2,
    size: '25 sq. feet',
    desc: "This is the project's description",
  },
  {
    img: CardImg1,
    size: '23 sq. feet',
    desc: "This is the project's description",
  },
  {
    img: CardImg2,
    size: '21 sq. feet',
    desc: "This is the project's description",
  },
  {
    img: CardImg3,
    size: '25 sq. feet',
    desc: "This is the project's description",
  },
];
