import { useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import Close from 'assets/Icons/Global/Close';

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  body,
  actionLabel,
  footer,
  disabled,
  customZIndex,
}) => {
  const modalVariants = {
    initial: {
      x: '-50%',
      y: '-45%',
      opacity: 0.5,
    },
    animate: {
      x: '-50%',
      y: '-50%',
      opacity: 1,
      transition: {
        duration: 0.1,
        ease: 'easeIn',
      },
    },
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling on the body
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
    }

    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrolling is re-enabled when component unmounts
    };
  }, [isOpen]);
  const handleClose = () => {
    console.log('onClsoe');

    onClose();
  };

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return;
    }

    onSubmit();
  }, [onSubmit, disabled]);

  if (!isOpen) {
    return null;
  }

  console.log(body);
  return (
    <>
      <motion.div
        variants={modalVariants}
        initial="initial"
        animate="animate"
        style={{ zIndex: customZIndex || '99999999' }}
        className="

           fixed
           top-[50%]
           left-[50%] translate-x-[-50%] translate-y-[-50%]
           flex flex-col

          "
      >
        {body}
      </motion.div>
      <div
        className="fixed z-[9999999] overflow-hidden h-[100vh] w-[100vw] top-0 left-0 bg-neutral-700 opacity-30"
        onClick={handleClose}
      />
    </>
  );
};

export default Modal;
