import CustomSwiper from 'components/Swiper/Swiper';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { SwiperSlide } from 'swiper/react';
import { breakpoints } from '../HomeSwipers';
import { interiorDetails } from '.';

const InteriorsSwiper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const [idx, setIdx] = useState(null);
  const [showTitle, setShowTitle] = useState(null);
  const textVariants = {
    initial: {
      opacity: 0.2,
      x: -10,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };

  const navigate = useNavigate();

  return (
    <div className="w-[90%] mx-auto flex flex-col gap-4 mt-9">
      <h1 className="text-[20px] font-semibold">Interiors</h1>
      <CustomSwiper
        showButton={false}
        paginated
        breakpoints={breakpoints}
        swiperSlideContent={Object.values(interiorDetails)?.map(
          (item, index) => (
            <SwiperSlide
              onClick={() => {
                navigate(
                  `/interior/know-more/${item.title
                    .replace(/[\s-]+/g, '_')
                    .toLowerCase()}`
                );
              }}
              style={{ minHeight: '400px' }}
            >
              <div className="overflow-hidden rounded-md shadow-1 h-[350px] object-cover">
                <div
                  className="w-full h-full relative"
                  onMouseOver={() => setShowTitle(index)}
                  onMouseLeave={() => setShowTitle(null)}
                >
                  {item.preview.type === 'image' ? (
                    <img
                      onClick={() => {
                        setIdx(index);
                        onOpen();
                      }}
                      src={item.preview.source}
                      alt={`swiper-img-${item.title}`}
                      className="h-full hover:scale-[1.1] transition-all duration-300 cursor-pointer ease-in w-full object-cover"
                    />
                  ) : (
                    <video
                      controls={false}
                      autoPlay
                      muted
                      onClick={() => {
                        setIdx(index);
                        onOpen();
                      }}
                      src={item.preview.source}
                      alt={`swiper-img-${item.title}`}
                      className="h-full hover:scale-[1.1] transition-all duration-300 cursor-pointer ease-in w-full object-cover"
                    />
                  )}
                  {showTitle === index && (
                    <motion.p
                      variants={textVariants}
                      initial="initial"
                      animate="animate"
                      className="absolute bottom-[20px] left-[10px] text-white text-[18px] font-medium"
                    >
                      {item.title}
                    </motion.p>
                  )}
                </div>
              </div>
            </SwiperSlide>
          )
        )}
      />
    </div>
  );
};

export default InteriorsSwiper;
