import Home from 'pages/Home/Home';
import AppRoutes from 'routes/AppRoutes';
import Footer from 'components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Navbar from './components/Navbar/Navbar';

const App = () => (
  <div>
    <button className="bg-white rounded-tl-[16px] shadow-2 rounded-bl-[16px] pl-[5px] right-0 top-[50%] translate-y-[-50%] fixed z-[99999999]">
      <a
        href="https://wa.me/+916363199108"
        className="contact-data-info"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="w-[56px] h-[56px] object-cover"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png"
          alt="whatsapp"
        />
      </a>
    </button>
    <Navbar />
    <AppRoutes />
    <Footer />
  </div>
);

export default App;
