import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

import { RiArrowDropDownLine } from 'react-icons/ri';

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button type="button" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};

const CustomAccordion = ({ idx }) => (
  <div className="bg-[white] w-[100%]">
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
          <CustomToggle eventKey={idx} className="w-full">
            <span className="font-medium">{idx + 1} .</span> Magna cupidatat
            enim
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={idx}>
          <Card.Body>
            <p className="leading-[140%]">
              {' '}
              Consectetur tempor duis anim commodo ipsum cillum voluptate in
              excepteur voluptate sit aliqua. Voluptate aliqua nulla proident
              esse irure eu cillum cillum voluptate aliquip. Consequat eu
              excepteur consectetur anim cillum anim.{' '}
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
);

export default CustomAccordion;
