import React from 'react';
import HeroBg from 'assets/Images/Home/Hero/banner.webp';
import Banner from 'assets/Images/Home/Hero/banner1.webp';
import DesignerForm from '../DesignerForm/DesignerForm';
import DesignerFormDesktop from '../DesignerFormDesktop/DesignerFormDesktop';

const Hero = () => {
  const c = '12';
  return (
    <div
      style={{
        height: 'calc(100vh/1.5)',
      }}
      className="w-full h-[65vh] md:h-[92vh] z-[1] relative"
    >
      <div className="relative w-full h-full z-[999999]">
        <img
          src={Banner}
          alt="hero-bg"
          className="object-cover z-[-1] w-full h-full"
        />
        <button className="flex justify-center  md:hidden  gap-[10px]  w-[90%] absolute bottom-[20px] rounded-[10px] shadow-1 left-[50%] translate-x-[-50%]  py-2 px-4 bg-bgPrimary text-white">
          <p>Start Your Free Trial </p>
          <button className="bg-[#ffdf11] button-new text-neutral-900 text-[12px] px-2 h-[fit-content]">
            New
          </button>
        </button>
        <div className="absolute w-[25%] top-[10%] right-[5.5%] shadow-2 z-[99999999]">
          <DesignerFormDesktop />
        </div>
      </div>
    </div>
  );
};

export default Hero;
